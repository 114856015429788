<template>
<div class="content-wrap">
  <i class="bg" :style="mainbg" />
  <div class="main" v-if="data">
    <div class="title">{{data.activity.name}}</div>
    <div class="tip">活动起止时间</div>
    <div>{{data.activity.start_date_str}} 至 {{data.activity.end_date_str}}</div>
    <div class="tip">活动地点</div>
    <div>{{data.activity.place}}</div>
    <div class="tip">承办方</div>
    <div>{{data.activity.organizer}}</div>
    <div class="tip">参与方</div>
    <div>{{data.activity.participant}}</div>
<!--    <div class="tip">参数选手</div>-->
<!--    <div>1,2,3</div>-->
<!--    <div class="tip">专业评委</div>-->
<!--    <div class="persons">-->
<!--      <div class="item" v-for="i in 4" :key="i">-->
<!--        <img src="" alt="" class="icon avatar" />-->
<!--        <div class="name">张三{{i}}</div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="tip">大众评委</div>-->
<!--    <div>名字</div>-->
  </div>



</div>
</template>

<script>
import api from "@/utils/api";
export default {
  name: "IndexView",
  data(){
    return {
      fid:undefined,
      action:undefined,
      data:undefined,
      actionDic:{checkin:"CheckinChat",vote:"Vote"},
      picture:undefined,
    }
  },
  computed:{
    mainbg(){
      if (this.picture){
        return `background-image: url(${this.picture})`
      }
      return "";
    },
  },
  mounted() {
    this.fid = this.$route.query.fid
    this.action = this.$route.query.action
    if(this.action !== undefined){
      this.$router.push({name:this.actionDic[this.action],params:{fid:this.fid}})
      return
    }

    this.loadData()
  },
  methods:{
    loadData(){
      api.get_activity().then(res=>{
        this.data = res
        this.picture = res.picture
        // document.title = res.activity.name
      }).catch(err=>{
        this.notice_warning(err)
      })
    }
  },
}
</script>

<style lang="scss" scoped>
/*深度选择器，后面再研究吧*/

i.bg {
  position: absolute;
  top: 0;right: 0;bottom: 0;left: 0;
  background-image: url("@/assets/images/mainbg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;

}
.content-wrap {
  padding: 55px 10px 30px 10px;
}
.main {
  color: #fafbff;
  text-shadow:0 0 5px rgba(0,0,0,1);
  text-align: left;
  font-size: 15px;
  .title {
    text-align: center;
    font-size: 18px;
    padding-bottom: 8px;
  }
  .tip { font-size: 14px;font-weight: bold}

  .persons {
    display: flex;justify-content: flex-start;flex-wrap: wrap;
    &>.item {
      &+.item { margin-left: 15px;}
      &>img.avatar {
        content: url('@/assets/images/header.jpg');
        width: 44px;height: 44px;
        border-radius: 50%;
        object-fit: cover;
      }
      flex-shrink: 0;
      &>.name { text-align: center;font-size: 0.9em;}
    }
  }
}
</style>