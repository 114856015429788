<template>
<div class="wrap">
  <i class="bg" :style="mainbg" />
  <div class="content-wrap">
    <div class="main">
      <div class="send-box">
        <img :src="data.avatar" alt="" class="icon header" v-if="data" />

        <textarea class="input" v-model="text" />
        <img src="" alt="按钮" class="icon random" @click="clickRandom()" />
      </div>
      <div class="btn" @click="clickSure()">发送</div>
    </div>
  </div>
</div>
</template>

<script>
import api from "@/utils/api";

export default {
  name: "CheckinChatView",
  props:["fid"],
  data(){
    return {
      data:undefined,
      blessings:[],
      b_i:0,
      text:"很高兴参加本次活动",
      picture:undefined,
    }
  },
  computed:{
    mainbg(){
      if (this.picture){
        return `background-image: url(${this.picture})`
      }
      return "";
    },
  },
  mounted() {
    this.loadData()
  },
  methods:{
    loadData(){
      api.get_flow_bg(this.fid).then(res=>{
        this.picture = res.picture
      }).catch(err=>{
        this.notice_warning(err)
      })
      api.get_userinfo().then(data=>{
        this.data = data
        console.error(this.data)
      }).catch(err=>{
        this.notice_warning(err)
      })

      api.get_blessings().then(data=>{
        this.blessings = data
      }).catch(err=>{
        this.notice_warning(err)
      })

    },
    clickRandom(){
      if (this.blessings.length ===0){
        return
      }
      if(this.b_i<this.blessings.length){
        this.text = this.blessings[this.b_i]
        this.b_i  = (this.b_i+1)%this.blessings.length
      }
    },
    clickSure(){
      api.do_checkin_bless(this.fid,this.text).then(()=>{
        this.clickRandom()
        // this.text = ""
        // alert("发送成功")
      }).catch(err=>{
        this.notice_warning(err)
      })
    }
  },
}
</script>

<style lang="scss" scoped>

.wrap {
  position: fixed;
  top: 0;right: 0;bottom: 0;left: 0;
  background-color: lightgrey;

  i.bg {
    position: absolute;
    top: 0;right: 0;bottom: 0;left: 0;
    background-image: url("@/assets/images/checkin_sj.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -1;

  }

  .content-wrap {
    position: absolute;
    top: 48px;right: 0;bottom: 70px;left: 0;
    display: flex;flex-direction: column;justify-content: flex-end;


    .main {
      padding: 0 20px;
      display: flex;flex-direction: column;align-items: center;

      .send-box {
        background-color: #3a76d5;
        border-radius: 15px;
        display: flex;justify-content: space-between;
        padding: 20px;box-sizing: border-box;
        width: 100%;
        .header {
          width: 40px;height: 40px;
          border-radius: 50%;
        }
        .input {
          flex: 1;
          border: none;background: none;
          color: white;font-size: 14px;
          height: 80px;
          margin: 0 10px;
        }
        .icon.random {
          content: url("@/assets/images/random.png");
          width: 30px;height: 30px;
        }
      }

      .btn {
        color:white;
        margin-top: 10px;
        font-size: 16px;
        padding: 8px 20px;
        border-radius: 15px;
        background-color: #3a76d5;
      }
    }


  }


}



</style>