<template>
<div class="content-wrap">
  <i class="bg" :style="mainbg" />
  <div class="title">投票</div>
  <div class="header" v-if="ballots && ballots.length>0">
    <div>
      <span>已投</span>
      <span>{{inValidCnt}}票</span>
      <span>/剩余</span>
      <span>{{validCnt}}票</span>
    </div>
    <div class="result" @click="clickResult()" >投票结果</div>
  </div>

  <div class="players" v-if="players.length>0">
    <div class="item" v-for="(v,i) in players"  :key="i">
      <img :src="v.avatar" class="icon header"/>
      <div class="name">{{v.name}}</div>
      <div class="btn" @click="clickPlayer(v)" >投票</div>
    </div>
  </div>


  <div class="mask" v-if="maskOpen" :class="{open:player!==undefined}" @click="clickMask()" ></div>
  <div class="ballots-wrap" :class="{open:player!==undefined}" v-if="maskOpen && ballots">
    <div class="ballots">
      <div class="item"  v-for="(v,i) in ballots"  :key="i"  :class="{selected:ballot&&ballot.id===v.id,disable:v.player_pid}" @click="clickBallot(v)" >{{v.ballot.name}}</div>
    </div>

    <div class="tip sure" v-if="ballot" >你确定要为 <span v-if="player">「{{player.name}}」</span> 投「{{ballot.ballot.name}}」吗？</div>
    <div class="tip" v-else >请选择你要为 <span v-if="player">「{{player.name}}」</span> 投的票</div>
    <div class="btn" :class="{active:ballot!==undefined}" @click="clickSure()"  >确认</div>
  </div>

</div>
</template>

<script>
import api from "@/utils/api";
export default {
  name: "CheckinView",
  props:["fid"],
  data(){
    return {
      player:undefined,
      ballot:undefined,
      maskOpen:false,
      players:[],
      ballots:[],
      picture:undefined
    }
  },
  watch:{
    $route(){
      // console.log("ccccccccc")
      this.loadData()
    }
  },
  computed:{
    mainbg(){
      if (this.picture){
        return `background-image: url(${this.picture})`
      }
      return "";
    },
    inValidCnt(){
      return this.ballots.length - this.validCnt
    },
    validCnt(){
      let cnt = 0
      for (const ballot of this.ballots) {
        if(ballot.player_pid == null){
          cnt ++
        }
      }
      return cnt
    }
  },
  mounted() {
    api.get_flow_bg(this.fid).then(res=>{
      this.picture = res.picture
      console.error(123,res)
    }).catch(err=>{
      this.notice_warning(err)
    })
    this.loadData()
  },
  methods:{
    loadData(){

      this.do_loadData().catch(err=>{
        this.notice_warning(err)
      })
    },
    async do_loadData(){
      this.players =  await api.get_palyes(this.fid)
      this.ballots = await api.get_ballots(this.fid)
    },
    checkMask(){
      setTimeout(()=>{
        this.maskOpen = this.player!==undefined;
        this.ballot = undefined
      },300)
    },
    clickMask(){
      this.player = undefined;
      this.checkMask()
    },
    clickPlayer(player){
      this.maskOpen = true
      this.player = player;
    },
    clickBallot(ballot){
      console.error(ballot.player_pid)
      if(ballot.player_pid===null){
        this.ballot = ballot;
      }
    },
    clickSure(){
      if(this.ballot===undefined){
        return
      }
      api.do_vote(this.ballot.id,this.player.id).then(()=>{

        this.ballot.player_pid = this.player.id
        this.clickMask()
        this.notice_success("投票成功")
      }).catch(err=>{
        this.notice_warning(err)
      })
    },
    clickResult(){
      const to = {name:'VoteResult',params:{fid:this.fid}}
      console.log(to,this.fid)
      this.$router.push(to)
    }

  },
}
</script>

<style lang="scss" scoped>

i.bg {
  position: absolute;
  top: 0;right: 0;bottom: 0;left: 0;
  background-image: url("@/assets/images/votebg_sj.jpg");
  //background-color: #3a76d5;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.content-wrap {
  padding: 8px 0;
  &>.title { font-size: 16px;padding: 10px 0}
  &>.header {
    display: flex;justify-content: space-between;align-items: center;
    padding-left:10px;box-sizing: border-box;
    font-size: 14px;

    .result {
      background-color: #3a76d5;
      color: white;
      padding: 0 10px 0 15px;
      height: 30px;line-height: 30px;
      border-radius: 15px 0 0 15px;
    }
  }

  $padding:6vw;
  $space:4vw;
  $itemW:(100vw - $padding * 2 - $space) / 2;
  $item-radius:4px;
  &>.players {
    overflow-y: auto;
    max-height: 80vh;
    padding: 0 $padding;
    display: flex;flex-wrap: wrap;justify-content: space-between;
    &>.item {
      margin-top: $space;
      width: $itemW;

      background-color: white;
      border-radius: $item-radius;
      padding-top: 10px;
      &>img.header {
        width: 80px;height: 80px;
        border-radius: 50%;
        object-fit: cover;
      }
      &>.name {
        font-size: 16px;
        padding-bottom: 4px;
      }
      &>.btn {
        background-color: #3a76d5;
        padding: 2px 0;
        color: white;
        font-size: 18px;
        border-radius: 0 0 $item-radius $item-radius;
      }
    }
  }

}

.mask {
  position: fixed;
  left: 0;bottom: 0;right: 0;top: 0;
  background-color: rgba(0,0,0,0);
  transition: all .3s ease-in-out;
  &.open {
    background-color: rgba(0,0,0,.5);
  }
}
.ballots-wrap {
  transition: all .3s ease-in-out;
  position: fixed;
  left: 0;right: 0;
  background-color: white;
  border-radius: 10px 10px 0 0;
  //box-shadow: 0 -1px 10px rgba(255,255,255,1);
  display: flex;flex-direction: column;align-items: center;
  padding:30px 0 40px 0;
  font-size: 15px;
  bottom: -400px;
  &.open {
    bottom: 0;
  }


  .ballots {
    width: 90vw;overflow-x: auto;

    //background-color: red;
    display: flex;justify-content: flex-start;
    &::-webkit-scrollbar {
      display: none;  /* 适用于 Chrome, Safari 和 Opera */
    }
    &>.item {
      padding: 10px 20px;
      border-radius: 2px;
      flex-shrink: 0; /* 防止子元素被缩小 */
      background-color: #ecf5ff;
      border:1px solid #d9ecff;
      color: #409EFF;
      &.selected {
        background-color: #f0f9eb;
        border-color: #e1f3d8;
        color: #67c23a;
      }
      &.disable {
        background-color: #f4f4f5;
        border-color: #e9e9eb;
        color: #909399;
      }
      &+.item { margin-left: 10px;}
    }
  }


  &>.tip {
    margin-top: 15px;
  }
  &>.btn {
    margin-top: 25px;

    border-radius: 4px;
    padding: 5px 20px;
    color: white;
    background-color: #95abec;
    &.active { background-color: #3a76d5;}
  }

}




</style>