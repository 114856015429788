import http from "./http";
import {logout,KEY_TOKEN} from "./http";


function login(token) {
    // console.error('token',token)
    sessionStorage.setItem(KEY_TOKEN,token)
}


/*
* 主要接口
* */

async function get_error(){
    const params = {}
    return await http.get('/wx/error',{params})
}
async function get_auth_url(){
    const params = {}
    return await http.get('/wx/auth_url',{params})
}

async function get_userinfo(){
    const params = {}
    return await http.get('/wx/userinfo',{params})
}

async function get_flow_bg(fid){
    const params = {}
    return await http.get(`/wx/flow_bg/${fid}`,{params})
}


async function get_flows(){
    const params = {}
    return await http.get('/wx/flows',{params})
}

async function get_checkininfo(){
    const params = {}
    return await http.get('/wx/checkin_info',{params})
}

async function get_activity(){
    const params = {}
    return await http.get('/wx/activity',{params})
}


async function do_checkin(phone){
    const data = {phone}
    return await http.post(`/wx/checkin`,data)
}
async function do_checkin_bless(fid,blessing){
    const data = {fid,blessing}
    return await http.post(`/wx/checkin/bless`,data)
}

async function get_ballots(fid){
    const params = {fid}
    return await http.get('/wx/vote/ballots',{params})
}
async function get_blessings(){
    const params = {}
    return await http.get('/wx/blessings',{params})
}

async function get_palyes(fid){
    const params = {fid}
    return await http.get('/wx/vote/players',{params})
}
async function get_statistics(fid){
    const params = {fid}
    return await http.get('/wx/vote/statistics',{params})
}
async function do_vote(rbid,player_pid){
    const data = {rbid,player_pid}
    return await http.post(`/wx/vote`,data)
}

const api_main = {get_flow_bg,get_userinfo,get_flows,get_activity,get_checkininfo,do_checkin_bless,get_blessings,get_error,get_auth_url,do_checkin,get_ballots,get_statistics,get_palyes,do_vote}


const api = {
    login,
    logout,
    ...api_main,
}

// Object.defineProperty(api,'mqtt_client',{
//     get(){
//         return mqtt_client
//     }
// })

Object.defineProperty(api,'logined',{
    get(){
        let token = sessionStorage.getItem(KEY_TOKEN)
        if (token != undefined){
            return true
        }
        return false
    }
})

Object.defineProperty(api,'role',{
    get(){
        let token = sessionStorage.getItem(KEY_TOKEN)
        if (token != undefined){
            return true
        }
        return false
    }
})



export default api